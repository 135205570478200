<template>
  <EmployeeEducationDetailSection></EmployeeEducationDetailSection>
</template>

<script>
import EmployeeEducationDetailSection from "@/modules/company/components/employees/details/education/EmployeeEducationDetailSection";
export default {
  name: "EmployeeEducationDetail",
  components: { EmployeeEducationDetailSection },
};
</script>

<style scoped></style>
