<template>
  <b-modal
    id="employee-page-detail-modal"
    :title="$t('EMPLOYEEFORM.EMPLOYEEDETAILS')"
    title-class="h4 font-weight-bold"
    lazy
    size="xl"
    hide-footer
  >
    <b-tabs
      pills
      fill
      justified
      v-model="currentTab"
      nav-class="nav-light-primary"
    >
      <!-- begin:: Employee General Details tab -->
      <b-tab :title="$t('COMPANY.REGISTRATION.GENERAL')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeeGeneralDetail></EmployeeGeneralDetail>
        </div>
      </b-tab>
      <b-tab :title="$t('EMPLOYEEFORM.PERSONAL')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeePersonalDetail></EmployeePersonalDetail>
        </div>
      </b-tab>
      <!-- end:: Employee General Details tab -->
      <b-tab :title="$t('COMPANY.REGISTRATION.CONTACT')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeeContactsDetail></EmployeeContactsDetail>
        </div>
      </b-tab>
      <b-tab :title="$t('EDUCATION.EDUCATION')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeeEducationDetail></EmployeeEducationDetail>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import EmployeeGeneralDetail from "@/modules/company/components/employees/details/general/EmployeeGeneralDetail";
import EmployeePersonalDetail from "@/modules/company/components/employees/details/personal/EmployeePersonalDetail";
import EmployeeContactsDetail from "@/modules/company/components/employees/details/contacts/EmployeeContactsDetail";
import EmployeeEducationDetail from "@/modules/company/components/employees/details/education/EmployeeEducationDetail";

export default {
  name: "EmployeePageDetailModal",
  components: {
    EmployeeEducationDetail,
    EmployeeGeneralDetail,
    EmployeePersonalDetail,
    EmployeeContactsDetail,
  },
  data() {
    return {
      currentTab: 0,
      busy: false,
    };
  },
};
</script>

<style scoped></style>
