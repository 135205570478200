<template>
  <b-form v-on:submit.prevent="onSubmit">
    <!-- School Name    -->
    <b-form-group label-cols="2" label-for="tax2" label="School">
      <b-form-input
        v-model="$v.form.schoolName.$model"
        :state="validateState('schoolName')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.schoolName.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- Course Name -->
    <b-form-group label-cols="2" label="Course">
      <b-form-input
        v-model="$v.form.courseName.$model"
        :state="validateState('courseName')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.courseName.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label-cols="2" label="Grade">
      <b-form-input
        v-model="$v.form.grade.$model"
        :state="validateState('grade')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.grade.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="From" label-cols-lg="2" content-cols-lg="6">
      <DatePicker
        value-type="format"
        type="year"
        v-model="$v.form.fromDate.$model"
        :state="validateState('fromDate')"
      ></DatePicker>
    </b-form-group>

    <b-form-group label="To" label-cols-lg="2" content-cols-lg="6">
      <DatePicker
        value-type="format"
        type="year"
        v-model="$v.form.toDate.$model"
        :state="validateState('toDate')"
      ></DatePicker>
    </b-form-group>

    <b-form-group label="Level" label-cols-lg="2" content-cols-lg="6">
      <b-form-select
        v-model="$v.form.educationLevel.$model"
        :options="educationLevels"
        :state="validateState('educationLevel')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.educationLevel.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="d-flex justify-content-end border-top pt-3">
      <button
        ref="employee-salary-button"
        class="btn btn-primary text-white"
        v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "EmployeeEducationDetailForm",
  mixins: [validationMixin],
  components: { DatePicker },
  props: {
    initialForm: {
      type: Object,
      value: {
        schoolName: "",
        courseName: "",
        grade: "",
        educationLevel: null,
        toDate: null,
        fromDate: null,
      },
    },
    isBusy: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        schoolName: this.initialForm.schoolName,
        courseName: this.initialForm.courseName,
        grade: this.initialForm.grade,
        educationLevel: this.initialForm.educationLevel,
        toDate: this.covertYear(this.initialForm.toDate),
        fromDate: this.covertYear(this.initialForm.fromDate),
      },

      educationLevels: [
        { value: 1, text: "High School" },
        { value: 2, text: "Diploma" },
        { value: 3, text: "Degree" },
        { value: 4, text: "Master" },
        { value: 5, text: "PHD" },
      ],
    };
  },
  validations: {
    form: {
      toDate: {
        required,
      },
      fromDate: {
        required,
      },
      schoolName: {
        required,
      },
      courseName: {
        required,
      },
      grade: {
        required,
      },
      educationLevel: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      console.log("Submitting form . . .");
      console.log("Education level", this.form.educationLevel);

      // Finally emit on submit event
      this.$emit("onSubmitClick", {
        course: this.form.courseName,
        school: this.form.schoolName,
        grade: this.form.grade,
        toDate: dayjs(this.form.toDate).format(),
        fromDate: dayjs(this.form.fromDate).format(),
        levelId: this.form.educationLevel,
      });
    },

    covertYear(year) {
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: year,
      });

      return date.format();
    },
  },
};
</script>

<style scoped></style>
