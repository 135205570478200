<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="h3">
        <span class="d-flex mb-5 align-items-center">
          <!--        <span class="svg-icon svg-icon-primary svg-icon-3x mr-4">-->
          <!--          <inline-svg src="/media/svg/icons/Layout/Layout-horizontal.svg" />-->
          <!--        </span>-->
          <span class="h4 font-weight-bolder">{{
            $t("EDUCATION.EDUCATION1")
          }}</span>
        </span>
      </div>
      <div class="card-toolbar">
        <div
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-b-tooltip.hover="$t('EMPLOYEE.HOVER.REFRESH')"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </div>

        <b-button
          variant="primary"
          @click="$bvModal.show('education-details-modal')"
        >
          {{ $t("EMPLOYEE.BUTTON") }}
        </b-button>
      </div>
    </div>
    <EmployeeEducationDetailTable
      :employee-id="id"
      :reload="reload"
    ></EmployeeEducationDetailTable>
    <EmployeeEducationDetailCreateModal
      :id="id"
      @submittedForm="handleSubmittedForm"
    ></EmployeeEducationDetailCreateModal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EmployeeEducationDetailTable from "@/modules/company/components/employees/details/education/EmployeeEducationDetailTable";
import EmployeeEducationDetailCreateModal from "@/modules/company/components/employees/details/education/EmployeeEducationDetailCreateModal";

export default {
  name: "EmployeeEducationDetailSection",
  components: {
    EmployeeEducationDetailCreateModal,
    EmployeeEducationDetailTable,
  },
  data() {
    return {
      reload: false,
    };
  },
  methods: {
    /**
     * Submit form.
     *
     * @param form
     */
    handleSubmittedForm(form) {
      console.log(form);
      this.$store.dispatch("fetchEmployeeEducationDetailsList", {
        id: this.id,
      });

      this.$bvModal.hide("education-details-modal");
    },

    /**
     * Calls child table to reload list.
     */
    refresh() {
      this.reload = !this.reload;
    },
  },
  computed: {
    ...mapGetters({
      id: "getSelectedEmployeeIdForDetails",
    }),
  },
};
</script>

<style scoped></style>
