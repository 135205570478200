<template>
  <div>
    <b-table
      id="employee-page-table"
      ref="employee-page-table"
      table-class="table-head-custom table-vertical-center"
      hover
      responsive
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      @row-clicked="showEmployeeDetails($event)"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(first_name)="data">
        <CommonEmployeeSummary :item="convertToSummary(data)" />
      </template>

      <template v-slot:cell(email)="data">
        {{ data.item.email }}
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showEmployeeDetails(data.item)"
            v-b-tooltip.hover="$t('EMPLOYEE.HOVER.EDIT')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <!-- begin::: edit-employee button -->
          <!-- end::: edit-employee button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger mr-3"
            v-if="getSuperAdminPermission"
            v-on:click="deleteEmployeeDetails(data)"
            v-b-tooltip.hover="$t('EMPLOYEE.HOVER.DELETE')"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->

      <!-- end: t-row -->

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="employees.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        @change="changePage"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>

    <EmployeePageDetailModal></EmployeePageDetailModal>
    <CompanyAdminAssignmentModal
      :employee="selectedEmployee"
    ></CompanyAdminAssignmentModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import EmployeePageDetailModal from "@/modules/company/components/employees/details/EmployeePageDetailModal";
import CompanyAdminAssignmentModal from "@/modules/company/components/employees/admin/CompanyAdminAssignmentModal";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";

export default {
  name: "EmployeeTable",
  props: ["reload"],
  components: {
    CommonEmployeeSummary,
    CompanyAdminAssignmentModal,
    EmployeePageDetailModal,
    AppLoader,
  },
  mounted() {
    this.$store.dispatch("emptyEmployeeList");

    this.getEmployees();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        {
          key: "first_name",
          label: this.$t("EMPLOYEE.HEADER"),
          sortable: true,
        },
        {
          key: "group",
          label: this.$t("EMPLOYEE.GROUP.BASE"),
          sortable: true,
        },
        { key: "actions", label: this.$t("EMPLOYEETABLE.ACTIONS") },
      ],
      selectedEmployee: {},
    };
  },
  methods: {
    /**
     * Fetch employees.
     *
     * @returns {Promise<any>}
     */
    async getEmployees() {
      try {
        const response = await this.$store.dispatch("getEmployeeList");
        console.log(response.data);
      } catch (error) {
        console.error(error);
        await this.$bvModal.msgBoxOk("Cannot display items");
      }
    },

    convertToSummary(row) {
      console.log(row.item);
      const item = row.item;
      const { first_name, last_name, unique_code, email, image_url } = item;

      return {
        name: `${first_name} ${last_name}`,
        code: unique_code,
        email: email,
        image_url: image_url,
      };
    },

    showEmployeeDetails(item) {
      console.log("Selected employee ", item);
      this.$bvModal.show("employee-page-detail-modal");
      this.$store.dispatch("assignEmployeeId", item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },

    deleteEmployeeDetails(row) {
      console.log(row.item);

      this.$bvModal
        .msgBoxConfirm(`This will delete ${row.item.first_name}`, {
          title: this.$t("ALERT.SURE"),
          centered: true,
          noCloseOnBackdrop: true,
          cancelVariant: "light",
        })
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("deleteEmployee", row.item.id)
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.refresh();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues bro");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    async changePage(page) {
      console.log(page);
      this.$store.commit("setEmptyEmployeeListPage", page);

      await this.getEmployees();
    },

    async refresh() {
      console.log("Reload Table!");
      await this.getEmployees();
    },

    assignAsAdmin(data) {
      console.log("Assigning ", data.item.id);
      this.selectedEmployee = {
        name: data.item.first_name,
        id: data.item.id,
      };
      this.$bvModal.show("employee-admin-assignment-modal");
    },
  },

  watch: {
    reload() {
      console.log("Reload Table!");
      this.refresh();
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.CompanyModule.employee.list.employees,
      meta: (state) => state.CompanyModule.employee.list.meta,
    }),
    ...mapGetters(["getSuperAdminPermission"]),
    ...mapGetters({
      items: "getEmployeeListItems",
      isBusy: "getEmployeeListItemsBusy",
    }),
  },
};
</script>

<style scoped></style>
