<template>
  <div>
    <b-table
      id="employee-page-table"
      ref="employee-page-table"
      table-class="table-head-custom table-vertical-center mt-5"
      :fields="fields"
      :items="items"
      :busy="isBusy"
      responsive
      show-empty
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(full_name)="data">
        <CommonEmployeeSummary :item="convertToSummary(data)" />
      </template>

      <template v-slot:cell(email)="data">
        {{ data.item.email }}
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showEmployeeDetails(data)"
            v-b-tooltip.hover="$t('EMPLOYEE.HOVER.EDIT')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <!-- begin::: resend verification email button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="resendEmail(data)"
            v-b-tooltip.hover="$t('EMPLOYEE.HOVER.MAIL')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Mail-heart.svg" />
            </span>
          </button>
          <!-- end::: resend verification email button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteEmployeeDetails"
            v-if="getSuperAdminPermission"
            v-b-tooltip.hover="$t('EMPLOYEE.HOVER.DELETE')"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->

      <!-- end: t-row -->

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="All employees verified"
          text-bottom="You have no unverified users. To add new user, please click on the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="employees">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="refresh"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>

    <EmployeePageDetailModal></EmployeePageDetailModal>
  </div>
</template>

<script>
import EmployeePageDetailModal from "@/modules/company/components/employees/details/EmployeePageDetailModal";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import { alert } from "sweetalert2";
import swal from "sweetalert";
import { mapGetters, mapState } from "vuex";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";

export default {
  name: "EmployeeUnverifiedTable",
  components: {
    CommonEmployeeSummary,
    AppEmptyList,
    EmployeePageDetailModal,
    AppLoader,
  },
  props: ["reload"],
  mounted() {
    this.$store.dispatch("emptyEmployeeList");
    this.getEmployees();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "#" },
        // A virtual column made up from two fields
        { key: "full_name", label: this.$t("EMPLOYEETABLE.FULLNAME") },
        // Email
        { key: "email", label: this.$t("EMPLOYEESGENERAL.MESSAGE.EMAIL") },
        { key: "actions", label: this.$t("EMPLOYEETABLE.ACTIONS") },
      ],
    };
  },
  methods: {
    getEmployees() {
      return this.$store
        .dispatch("getEmployeeList", 3)
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },

    /**
     * Convert to summary obj.
     *
     * @param row
     */
    convertToSummary(row) {
      const item = row.item;
      const { first_name, last_name, unique_code, email, image_url } = item;

      return {
        name: `${first_name} ${last_name}`,
        code: unique_code,
        email: email,
        image_url: image_url,
      };
    },

    showEmployeeDetails(row) {
      console.log("Selected employee ", row);
      this.$bvModal.show("employee-page-detail-modal");
      this.$store.dispatch("assignEmployeeId", row.item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },

    deleteEmployeeDetails() {
      swal({
        title: this.$t("ALERT.SURE"),
        text: this.$t("ALERT.WARNING"),
        icon: "warning",
        showCancelButton: true,
        dangerMode: true,
        buttons: [this.$t("BUTTON.CANCEL"), this.$t("BUTTON.OKAY")],
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then((willDelete) => {
        if (willDelete) {
          swal(this.$t("ALERT.CONFIRM"), {
            icon: "success",
          });
        }
      });
    },

    resendEmail(row) {
      console.log(row);
      const user = row.item.first_name + " " + row.item.email;
      swal({
        title: this.$t("ALERT.SURE"),
        text: this.$t("ALERT.EMAIL") + user,
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: this.$t("BUTTON.CANCEL"),
            visible: false,
            closeModal: true,
          },
          confirm: {
            text: this.$t("BUTTON.OKAY"),
            closeModal: false,
          },
        },
      })
        .then((willDelete) => {
          if (!willDelete) {
            throw null;
          }

          console.log("Will Delete!");
          return this.$store.dispatch("resendVerificationEmail", row.item.id);
        })
        .then(() => {
          swal("Verification Email sent to " + user, {
            icon: "success",
          });
        })
        .catch((err) => {
          if (err) {
            swal("Sorry", "Failed to resend email", "error");
          } else {
            swal.stopLoading();
            swal.close();
          }
        });
    },

    refresh() {
      console.log("Reload Table!");
      this.$refs["employee-page-table"].refresh();
    },
  },

  watch: {
    reload() {
      console.log("Reload Table!");
      this.$refs["employee-page-table"].refresh();
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.CompanyModule.employee.list.employees,
      meta: (state) => state.CompanyModule.employee.list.meta,
    }),

    ...mapGetters(["getSuperAdminPermission"]),
    ...mapGetters({
      items: "getEmployeeListItems",
      isBusy: "getEmployeeListItemsBusy",
    }),
  },
};
</script>

<style scoped></style>
