<template>
  <b-modal
    id="education-details-edit-modal"
    :title="$t('EDUCATION.MODAL.TITLE')"
    size="lg"
    lazy
    hide-footer
  >
    <EmployeeEducationDetailForm
      :initial-form="form"
      :is-busy="isBusy"
      @onSubmitClick="submitForm"
    ></EmployeeEducationDetailForm>
  </b-modal>
</template>

<script>
import EmployeeEducationDetailForm from "@/modules/company/components/employees/details/education/EmployeeEducationDetailForm";

export default {
  name: "EmployeeEducationDetailEditModal",
  components: { EmployeeEducationDetailForm },
  props: {
    id: {
      type: Number,
      required: true,
    },
    detailId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isBusy: false,
    };
  },

  methods: {
    submitForm(form) {
      console.log(form);

      const dto = {
        school_name: form.school,
        course_name: form.course,
        grade: form.grade,
        education_level_id: form.levelId,
        from_date: form.fromDate,
        to_date: form.toDate,
        employee_id: this.id,
        id: this.form.id,
      };

      console.log("DTO is", dto);

      this.isBusy = true;
      this.$store
        .dispatch("updateEmployeeEducationDetailsForm", {
          form: dto,
        })
        .then((res) => {
          this.$bvModal.msgBoxOk("Updated employee education details");
          this.$emit("submittedForm", res.data);
        })
        .finally(() => (this.isBusy = false));
    },
  },
};
</script>

<style scoped></style>
