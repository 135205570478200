<template>
  <div>
    <EmployeePageTableControl class="mb-5" />

    <AppPageCard>
      <template #card-title>
        {{ $t("EMPLOYEE.HEADER") }}
      </template>

      <template #card-toolbar>
        <div
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
          v-b-tooltip.hover="$t('EMPLOYEE.HOVER.REFRESH')"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </div>
        <b-button
          variant="light-primary"
          class="font-weight-bolder"
          v-b-toggle.employee-list-sidebar
        >
          {{ $t("EMPLOYEE.BUTTON") }}
        </b-button>
      </template>

      <b-sidebar
        ref="employee-list-sidebar-ref"
        id="employee-list-sidebar"
        backdrop
        right
        lazy
        :title="$t('EMPLOYEEFORM.CREATE')"
        sidebar-class="offcanvas"
        header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
        bg-variant="white"
      >
        <template v-slot:header-close>
          <button
            class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
          >
            <i class="ki ki-close icon-xs"></i>
          </button>
        </template>
        <div class="px-10 mt-5">
          <EmployeeForm
            v-slot:default="{}"
            :title="$t('EMPLOYEEFORM.EMPLOYEEDETAILS')"
            v-on:success="closeSideBar"
          ></EmployeeForm>
        </div>
      </b-sidebar>

      <b-tabs pills nav-class="nav-primary">
        <b-tab lazy :title="$t('ECOMMERCE.COMMON.ALL')">
          <EmployeeTable :reload="reload"></EmployeeTable>
        </b-tab>
        <b-tab lazy :title="$t('BUTTON.UNVERIFIED')">
          <EmployeeUnverifiedTable :reload="reload"></EmployeeUnverifiedTable>
        </b-tab>
      </b-tabs>
    </AppPageCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EmployeeForm from "@/modules/company/components/employees/EmployeePageCreateForm";
import EmployeeTable from "@/modules/company/components/employees/EmployeePageTable";
import EmployeeUnverifiedTable from "@/modules/company/components/employees/EmployeeUnverifiedTable";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import EmployeePageTableControl from "@/modules/company/components/employees/details/control/EmployeePageTableControl";

export default {
  name: "EmployeeList",
  components: {
    EmployeePageTableControl,
    AppPageCard,
    EmployeeUnverifiedTable,
    EmployeeTable,
    EmployeeForm,
  },
  data() {
    return {
      reload: false,
    };
  },
  mounted() {
    console.log("Employee List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      { title: this.$t("SIDEBAR.EMPLOYEE"), route: "/company/employee/list" },
    ]);
  },
  methods: {
    closeSideBar() {
      const sideBar = this.$refs["employee-list-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
