import { render, staticRenderFns } from "./EmployeeGeneralDetail.vue?vue&type=template&id=6b29f628&scoped=true&"
import script from "./EmployeeGeneralDetail.vue?vue&type=script&lang=js&"
export * from "./EmployeeGeneralDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b29f628",
  null
  
)

export default component.exports