<template>
  <div>
    <b-table
      id="education-page-table"
      ref="education-page-table"
      table-class="table-head-custom table-head-bg table-vertical-center mt-5"
      responsive
      outlined
      show-empty
      :fields="fields"
      :items="items"
      :busy="isBusy"
      hover
      @row-clicked="showEducationDetails"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(school)="data">
        <div class="d-flex flex-column my-3">
          <span class="font-weight-bolder">
            {{ data.item.school }}
          </span>
        </div>
      </template>

      <template v-slot:cell(degree)="data">
        {{ data.item.degree }}
      </template>

      <template v-slot:cell(field)="data">
        {{ data.item.field }}
      </template>

      <template v-slot:cell(grade)="data">
        {{ data.item.grade }}
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showEducationDetails(data.item)"
            v-b-tooltip.hover="$t('EDUCATION.EDIT')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger mr-3"
            v-on:click="deleteEducationDetails(data)"
            v-b-tooltip.hover="$t('EDUCATION.DELETE')"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->

      <!-- end: t-row -->

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No details added"
          text-bottom="To add details, click Create button"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        @change="refresh"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
      ></b-pagination>
    </div>

    <EmployeeEducationDetailEditModal
      :id="employeeId"
      :detail-id="selectedDetailId"
      :form="form"
      @submittedForm="fetch"
    ></EmployeeEducationDetailEditModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/core/plugins/vue-i18n";
import dayjs from "dayjs";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import EmployeeEducationDetailEditModal from "@/modules/company/components/employees/details/education/EmployeeEducationDetailEditModal";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "EmployeeEducationDetailTable",
  components: { AppEmptyList, EmployeeEducationDetailEditModal, AppLoader },

  props: {
    employeeId: {
      required: true,
      type: Number,
    },
    reload: {
      type: Boolean,
    },
  },

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "school_name", label: this.$t("EDUCATION.SCHOOL") },
        { key: "course", label: this.$t("EDUCATION.FIELD") },
        { key: "education_level.name", label: this.$t("EDUCATION.DEGREE") },
        { key: "grade", label: this.$t("EDUCATION.GRADE") },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
      isBusy: false,
      selectedDetailId: 0,
      form: {},
    };
  },

  mounted() {
    this.fetch();
  },
  methods: {
    /**
     * Fetch list.
     */
    fetch() {
      this.isBusy = true;

      const id = this.$store.getters.getSelectedEmployeeIdForDetails;
      console.log("Id", id);
      this.$store
        .dispatch("fetchEmployeeEducationDetailsList", {
          id: id,
        })
        .finally(() => (this.isBusy = false));
    },

    /**
     * Change page.
     *
     * @param page
     */
    refresh(page) {
      console.log("Set to page: ", page);

      this.$store.commit("setEmployeeEducationDetailsListPage", page);
      this.fetch();
    },

    /**
     * Show details.
     *
     * @param item
     */
    showEducationDetails(item) {
      console.log(item);

      this.form = {
        schoolName: item.school_name,
        courseName: item.course,
        grade: item.grade,
        educationLevel: item.education_level.id,
        toDate: dayjs(item.from_period).toDate(),
        fromDate: dayjs(item.to_period).toDate(),
        id: item.employee_educational_id,
      };
      console.log(this.form);

      this.$bvModal.show("education-details-edit-modal");
    },

    /**
     * Delete single edu details.
     *
     * @param data
     */
    deleteEducationDetails(data) {
      const item = data.item;
      console.log(item);

      this.$bvModal
        .msgBoxConfirm(
          this.$t("EDUCATION.ACTIONS.DELETE.CONFIRM", {
            name: `${item.course} - ${item.school_name}`,
          }),
          {}
        )
        .then((value) => {
          console.log(value);
          if (value) this.attemptToDelete(item);
        });
    },

    /**
     * Delete.
     *
     * @param item
     */
    attemptToDelete(item) {
      this.$store
        .dispatch("deleteEmployeeEducationDetailsForm", {
          id: item.employee_educational_id,
        })
        .then(() => {
          this.$bvModal.msgBoxOk(
            this.$t("EDUCATION.ACTIONS.DELETE.SUCCESS", {
              name: `${item.course} - ${item.school_name}`,
            })
          );
          this.fetch();
        })
        .catch(() =>
          this.$bvModal.msgBoxOk("Failed to delete details", {
            okVariant: "danger",
          })
        );
    },
  },

  watch: {
    reload(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.fetch();
    },
  },

  computed: {
    ...mapGetters({
      items: "getEmployeeEducationDetailsList",
      pagination: "getEmployeeEducationDetailsListPagination",
    }),
  },
};
</script>

<style scoped></style>
