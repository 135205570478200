<template>
  <EmployeePersonalDetailForm></EmployeePersonalDetailForm>
</template>

<script>
import EmployeePersonalDetailForm from "@/modules/company/components/employees/details/personal/EmployeePersonalDetailForm";
export default {
  name: "EmployeePersonalDetail",
  components: { EmployeePersonalDetailForm },
};
</script>

<style scoped></style>
