<template>
  <div>
    <h6 class="font-weight-bolder">{{ $t("BUTTON.PHOTO") }}</h6>
    <!-- begin:: Employee Image  -->
    <div class="d-flex justify-content-center">
      <b-avatar
        :src="image_url"
        variant="light"
        size="15em"
        class="mx-auto"
      ></b-avatar>
    </div>
    <!-- end:: Employee Image  -->

    <EmployeeImageUpload></EmployeeImageUpload>

    <b-form class="form" v-on:submit.prevent="onSubmit">
      <h4 class="font-weight-bolder mb-5"></h4>

      <b-form-group
        id="first_name"
        label-for="first_name"
        :label="$t('EMPLOYEESGENERAL.MESSAGE.FIRSTNAME')"
        label-cols-lg="2"
      >
        <b-form-input
          id="first_name"
          :placeholder="$t('EMPLOYEEFORM.NAME')"
          v-model="$v.form.first_name.$model"
          :state="validateState('first_name')"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.first_name.required">
          {{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="last_name"
        label-for="last_name"
        :label="$t('EMPLOYEESGENERAL.MESSAGE.LASTNAME')"
        label-cols-lg="2"
      >
        <b-form-input
          id="last_name"
          :placeholder="$t('EMPLOYEEFORM.LASTNAME')"
          v-model="$v.form.last_name.$model"
          :state="validateState('last_name')"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.last_name.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="email"
        label-for="email"
        :label="$t('EMPLOYEESGENERAL.MESSAGE.EMAIL')"
        label-cols-lg="2"
      >
        <b-form-input
          id="email"
          :placeholder="$t('EMPLOYEEFORM.EMAIL')"
          v-model="$v.form.email.$model"
          :state="validateState('email')"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.email.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="unique_code"
        label-for="unique_code"
        :label="$t('EMPLOYEESGENERAL.MESSAGE.UNIQUE')"
        label-cols-lg="2"
      >
        <b-form-input
          id="unique_code"
          :placeholder="$t('EMPLOYEEFORM.UNIQUECODE')"
          v-model="$v.form.unique_code.$model"
          :state="validateState('unique_code')"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.unique_code.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="$t('EMPLOYEESGENERAL.MESSAGE.EMPLOYEMENT')"
        label-cols-lg="2"
      >
        <DatePicker
          id="employment_date"
          value-type="format"
          :placeholder="$t('EMPLOYEEFORM.STARTDATE')"
          v-model="$v.form.employment_date.$model"
          :state="validateState('employment_date')"
        >
        </DatePicker>
      </b-form-group>

      <div class="d-flex justify-content-end pt-3">
        <b-button type="reset" variant="light" class="mr-2">
          {{ $t("EMPLOYEES.DETAILS.RESET") }}
        </b-button>

        <button
          ref="employee-general-button"
          class="btn btn-primary text-white font-weight-light"
          v-bind:class="{ 'spinner spinner-right spinner-light': formBusy }"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import swal from "sweetalert";
import EmployeeImageUpload from "@/modules/company/components/employees/image/EmployeeImageUpload";

export default {
  name: "EmployeeGeneralDetailForm",
  components: { EmployeeImageUpload, DatePicker },
  mixins: [validationMixin],
  data: function () {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        unique_code: "",
        employment_date: new Date(),
        id: "",
      },
      image: null,
      image_url: "",
      formBusy: false,
      imageBusy: false,
      mainProps: { blank: true, width: 200, height: 200, class: "m1" },
      myFiles: [],
    };
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
      },
      unique_code: {
        required,
      },
      employment_date: {},
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee General Details Form:", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.formBusy = !this.formBusy;
      this.$store
        .dispatch("submitEmployeeGeneralDetails", this.form)
        .then(() => {
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
        })
        .finally(() => (this.formBusy = !this.formBusy));
    },
  },
  mounted() {
    this.$store.dispatch("fetchGeneralDetailForm").then((response) => {
      const formData = response.data.data;

      console.log("Employment date", formData.date);
      this.form = {
        id: formData.id,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        unique_code: formData.unique_code,
        employment_date: formData.date,
      };
      this.image_url = formData.image_url;
      console.log("Image URL is", this.image_url);
    });
  },
  computed: {
    ...mapState({
      employeeId: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.employeeId,
    }),
    ...mapGetters({ imageUpload: "getUploadEmployeeImage" }),
  },

  watch: {
    imageUpload(newImage) {
      if (newImage) this.image_url = newImage;
    },
  },
};
</script>

<style scoped></style>
