<template>
  <AppTableControl
    :search-place-holder="$t('COMMON.Actions.Search')"
    @query-debounced="searchName"
    @export-excel-clicked="downloadReport(1)"
    @export-pdf-clicked="downloadReport(2)"
    no-filter
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";
export default {
  name: "EmployeePageTableControl",
  components: { AppTableControl },
  methods: {
    /**
     * Search name.
     *
     * @param nameQuery
     */
    searchName(nameQuery) {
      console.log(nameQuery);

      this.$store.dispatch("changeEmployeeListBaseFilter", {
        base: nameQuery,
      });

      this.$store.dispatch("getEmployeeList");
    },

    /**
     * Download report
     * @param type
     */
    async downloadReport(type) {
      try {
        this.$bvToast.toast(this.$t("COMMON.Download.Pending"), {
          variant: "primary",
        });

        await this.$store.dispatch("downloadEmployeeListReport", {
          typeId: type,
        });

        this.$bvToast.toast(this.$t("COMMON.Download.Success"), {
          variant: "primary",
        });
      } catch (e) {
        console.error(e);

        await this.$bvModal.msgBoxOk(this.$t("COMMON.Download.Failed"));
      }
    },
  },
};
</script>

<style scoped></style>
