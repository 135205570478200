<template>
  <b-modal
    id="education-details-modal"
    title="Education Details"
    size="lg"
    lazy
    hide-footer
  >
    <EmployeeEducationDetailForm
      @onSubmitClick="submitForm"
      :initial-form="{}"
    ></EmployeeEducationDetailForm>
  </b-modal>
</template>

<script>
import EmployeeEducationDetailForm from "@/modules/company/components/employees/details/education/EmployeeEducationDetailForm";

export default {
  name: "EmployeeEducationDetailCreateModal",
  components: { EmployeeEducationDetailForm },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    submitForm(form) {
      console.log(form);

      const dto = {
        school_name: form.school,
        course_name: form.course,
        grade: form.grade,
        education_level_id: form.levelId,
        from_date: form.fromDate,
        to_date: form.toDate,
        employee_id: this.id,
      };

      console.log("DTO is", dto);
      this.$store
        .dispatch("submitEmployeeEducationDetailsForm", {
          form: dto,
        })
        .then((res) => {
          this.$bvModal.msgBoxOk("Updated employee education details");
          this.$emit("submittedForm", res.data);
        });
    },
  },
};
</script>

<style scoped></style>
